import React from "react";
import { RichText } from "./RichText";
import { Section } from "./Section";
import PrimaryLinkButton from "./PrimaryLinkButton";
import Arrow from "./Arrow";
import dayjs from "../utils/dayjs";
import { AnchorLink } from "gatsby-plugin-anchor-links";

export const Article = ({
  title,
  content,
  author,
  tags,
  publicationDate,
  createdAt,
  articleTeasers,
}) => {
  return (
    <Section width={11} className="bg-gray-100-gradient-50 pt-3rem pt-lg-5rem">
      <div className="col-12 col-lg-7">
        <AnchorLink to={`/blog`} className="d-block pb-1rem">
          <Arrow direction="left" />
        </AnchorLink>
        <h1 className="c-black font-weight-bold fs-2 d-flex align-items-center cursor-pointer text-uppercase pb-2rem">
          {title}
        </h1>
        {content && (
          <RichText
            className="pb-2rem"
            text={content.raw}
            references={content.references}
          />
        )}
      </div>
      <div className="col-12 offset-lg-1 col-lg-4">
        {articleTeasers && <ArticleTeasers articleTeasers={articleTeasers} />}
      </div>
      <div className="col-12">
        <div
          className={`py-3rem border-top bw-2 bc-blue`}
          style={{ maxWidth: "464px" }}
        >
          <div className="pb-3rem">
            <ArticleInfo
              {...{
                author,
                tags,
                publicationDate: publicationDate || createdAt,
              }}
            />
          </div>
          <div className="font-weight-bold fs-1_75 pb-1_5rem">
            Spodobał Ci się ten artykuł?
            <br />
            Chcesz dowiedzieć się więcej?
          </div>
          <div className="font-weight-bold fs-2 pb-2rem">NAPISZ DO NAS!</div>
          <div>
            <Arrow />
          </div>
        </div>
      </div>
    </Section>
  );
};

const ArticleInfo = ({ author, tags, publicationDate }) => (
  <div className="d-flex">
    {author.image && (
      <div
        className="bgImage rounded-circle h-6rem w-6rem"
        style={{ backgroundImage: `url(${author.image.fluid.src})` }}
      />
    )}
    <div className="pl-1rem">
      <div className="font-weight-bold fs-1_25">{author.name}</div>
      <div className="fs-1_5 py-0_25rem">
        {dayjs(publicationDate).format("D MMMM, YYYY")}
      </div>
      {tags && <TagList tags={tags} />}
    </div>
  </div>
);

const TagList = ({ tags }) => (
  <ul className="list-unstyled">
    {tags.map(({ name, slug }) => (
      <li className="d-inline-block pr-2rem" key={slug}>
        <AnchorLink to={`/tags/${slug}`} className="text-underline c-blue">
          {name}
        </AnchorLink>
      </li>
    ))}
  </ul>
);

const ArticleTeasers = ({ articleTeasers }) => {
  return (
    <>
      <h3 className="fs-1_5 font-weight-bold pb-2rem">Polecane artykuły:</h3>
      <ul className="list-unstyled">
        {articleTeasers.map(({ id, slug, mainImage, title }) => (
          <li key={id} className="d-flex pb-2rem align-items-center">
            <AnchorLink to={`/blog/${slug}`}>
              <img src={mainImage.file.url} alt="icon" className="w-6rem" />
            </AnchorLink>
            <AnchorLink
              to={`/blog/${slug}`}
              className="c-black fs-0_875 font-weight-bold d-flex cursor-pointer text-decoration-none pl-2rem"
            >
              {title}
            </AnchorLink>
          </li>
        ))}
      </ul>
    </>
  );
};
