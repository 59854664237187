import "../scss/app.scss";
import React from "react";
import { Footer } from "../component/Footer";
import { Navbar } from "../component/Navbar";
import { Article } from "../component/Article";
import { Contact } from "../component/Contact";
import { Identifications } from "../component/Identifications";
import AppHelmet from "../component/AppHelmet";
import { graphql } from "gatsby";
import { usePageInit } from "../component/hook/usePageInit";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import { Cookies } from "../component/Cookies";

const components = {
  ContentfulNavbar: Navbar,
  ContentfulContact: Contact,
  ContentfulArticlePage: Article,
  ContentfulFooter: Footer,
  ContentfulIdentifications: Identifications,
};

function ArticlePage({ pageContext, data }) {
  const { allContentfulArticlePage: articleTeasers } = data;
  const { sections } = pageContext;
  const { popup } = usePageInit();

  return (
    <main>
      {popup}
      {sections?.map(
        ({ id, __typename, ...props }) =>
          __typename === "ContentfulArticlePage" && (
            <AppHelmet
              title={props.title}
              image={
                props.mainImage.file.url.startsWith("//")
                  ? `https:${props.mainImage.file.url}`
                  : props.mainImage.file.url
              }
              description={documentToPlainTextString(
                JSON.parse(props.excerpt.raw)
              )}
              key={id}
            />
          )
      )}
      {sections?.map(({ id, __typename, ...props }) => {
        const SectionTag = components[__typename];

        if (__typename === "ContentfulArticlePage") {
          props.articleTeasers = articleTeasers.nodes;
        }

        return SectionTag && <SectionTag {...props} key={id} />;
      })}
      <Cookies />
    </main>
  );
}

export default ArticlePage;

export const articleTemplateQuery = graphql`
  query articleTemplateQuery {
    allContentfulArticlePage(limit: 5) {
      nodes {
        id
        title
        slug
        excerpt {
          raw
        }
        mainImage {
          file {
            url
          }
        }
      }
    }
  }
`;
